<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="标题" prop="title">
				  <el-input v-model="form.title" placeholder="请输入标题" type="text" clearable />
				</el-form-item>
				<el-form-item label="作者" prop="anthor">
				  <el-input v-model="form.anthor" placeholder="请输入作者" type="text" clearable />
				</el-form-item>
				<el-form-item label="文章类型" prop="type_id">
					 <el-select v-model="form.type_id" placeholder="请选择文章类型" size="medium" class="w-100">
					   <el-option v-for="(item,index) in types" :key="index"
						 :label="item | tree"
						 :value="item.id">
					   </el-option>
					 </el-select>
				  </el-form-item>
				<el-form-item label="图片">
					<div class="flex flex-wrap">
						<div class="border rounded flex-all mr-3 mb-3 position-relative" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
						@click="chooseImage(index)"
						v-for="(item,index) in images" :key="index"
						v-dragging="{ item: item, list: images, group: 'imgs' }">
							<img :src="item.url" class="w-100 h-100" v-if="item.url">
							<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
							<i class="el-icon-delete text-white position-absolute p-1" style="top: 0;right: 0;background-color: rgba(0,0,0,0.4);" 
							v-if="item.url"
							@click.stop="delImage(index)"></i>
						</div>
						<div class="border rounded flex-all mr-3 mb-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
						@click="chooseImage(-1)"
						v-if="images.length < 10"
						>
							<i class="el-icon-plus text-muted" style="font-size: 30px;"></i>
						</div>
					</div>
				    <small class="d-block text-secondary" style="line-height: 20px;">默认第一张是主图</small>
			    </el-form-item>
				<el-form-item label="内容">
				   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" />
				</el-form-item>
				<el-form-item  label="发布状态">
				   <el-radio-group v-model="form.is_pub">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">停用</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="排序" prop="sort">
				  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
		tinymce
	},
	data() {
		return {
			preUrl: 'article',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				id:8,
				keyword: '',
			},
			form:{
				title:'',
				anthor:'安徽省电力协会',
				type_id:'',
				cover:'',
				url:'',
				content:'',
				sort:100,
				is_pub:1,
				video:'',
			},
			rules:{
				title: [
				  { required: true, message: '请输入文章标题', trigger: 'blur' }
				],
				type_id: [
				  { required: true, message: '请选择文章类型', trigger: 'blur' }
				],
			},
			tinymceFlag:0,
			types:[],
			images:[],
		}
	},
	mounted() {
		if(this.$route.query.article){
			 this.form = this.$route.query.article
			 this.images = this.$route.query.article.images
		}
		this.getArticleTypes()
	},
	methods:{
		getArticleTypes(){
			this.axios.get(`/manage/article_type/getTree`, {
			  params: this.queryForm
			}).then(res=>{
			  if(res.status){
				this.types = res.data
			  }
			})
		},
		chooseImage(){
			this.app.chooseImage((res)=>{
			  this.form.cover = res[0].id
			  this.form.url = res[0].url
			})
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
			  this.form.images = this.images
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
		chooseImage(index){
			const MAX = 10
			let conut = MAX - this.images.length
			this.app.chooseImage(res=>{
				let list = []
				if(index === -1){
					list = [...this.images,...res]
				}else{
					list = [...this.images]
					list[index] = res[0]
				}
				this.images = list
			}, index === -1 ? conut : 1)
		},
		delImage(index){
			this.$confirm('删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let list = [...this.images]
				list.splice(index,1)
				this.images = list
			}).catch(() => {
				
			});
		},
	},
	filters: {
	    tree(item) {
	        if (item.level == 0) {
	          return item.name
	        }
	        let str = ''
	        for (let i = 0; i < item.level; i++) {
	          str += i == 0 ? '|--' : '--'
	        }
	        return str + ' ' +item.name;
	    }
	},
}
</script>

<style>
</style>